import { useRouter } from "next/router";
import Header01 from "./Header";
import Footer from "./Footer";

export default function Layout({ children }) {
  const router = useRouter();

  return (
    <>
      {/* <Header01 /> */}
      {children}
      <Footer />
    </>
  );
}
