import React, { useContext, useState } from "react";

import Container from "@mui/material/Container";
import Hidden from "@mui/material/Hidden";
import useMediaQuery from "@mui/material/useMediaQuery";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { AppContext } from "../src/utils";
import { Paper, Typography } from "@mui/material";
import { ToastNotify } from "../src/ConnectivityAssets/hooks";
import CustomButton from "../src/Utils/CustomButton";
// import { HashLink } from "react-router-hash-link";
import Link from "next/link";

const logo = "/images/logo.png";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#1C0D38 !important",
    justifyContent: "center",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

const StyledTypography = ({ children, margin }) => {
  return (
    <Typography
      variant="subtitle2"
      mr={margin}
      sx={{
        fontSize: "13px",
        fontWeight: "700",
        transition: "0.4s",
        cursor: "pointer",
        "&:hover": {
          color: "#EF4D84",
          borderBottom: "1px solid #EF4D84",
        },
        "&:active": {
          color: "#EF4D84",
          borderBottom: "1px solid #EF4D84",
        },
      }}
    >
      {children}
    </Typography>
  );
};

const LinkArray = [
  {
    title: "About",
    link: "/#About",
  },
  {
    title: "Team",
    link: "/#Team",
  },
  {
    title: "Ecosystem",
    link: "/#Ecosystem",
  },
];

const aLinkArray = [
  {
    title: "Whitepaper",
    link: "https://renq.io/whitepaper.pdf",
  },
  {
    title: "Win 250K",
    link: "https://renq.io/win-250k",
  },
  {
    title: "How to Buy",
    link: "https://renq.io/how-to-buy",
  },
  {
    title: "Contact Us",
    link: "https://renq.io/contact/",
  },
  {
    title: "Dashboard",
    link: "/dashboard",
  },
];

export default function Header() {
  const { account, connect, disconnect } = useContext(AppContext);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const matches = useMediaQuery("(max-width:1080px)");
  const matches1 = useMediaQuery("(max-width:1279px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box mb={3} display="flex" justifyContent="center">
        <Link href="#">
          <img width="200px" src={logo} alt="logo" />
        </Link>
      </Box>
      <List>
        {LinkArray.map(({ title, link }, i) => (
          <a
            key={i}
            href={link}
            smooth
            style={{ textDecoration: "none", color: "#fff" }}
          >
            <ListItem
              style={{
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <StyledTypography>{title}</StyledTypography>
            </ListItem>
          </a>
        ))}

        {aLinkArray.map(({ title, link }, i) => (
          <a
            key={i}
            href={link}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            <ListItem
              style={{
                justifyContent: "center",
                marginTop: "5px",
              }}
            >
              <StyledTypography>{title}</StyledTypography>
            </ListItem>
          </a>
        ))}
      </List>
      <Box mt={2} display="flex" justifyContent="center">
        {account ? (
          <CustomButton sx={{ width: "180px" }} onClick={() => disconnect()}>
            {account.slice(0, 4) + "..." + account.slice(-4)}
          </CustomButton>
        ) : (
          <CustomButton sx={{ width: "180px" }} onClick={() => connect()}>
            Connect Wallet
          </CustomButton>
        )}
      </Box>
    </div>
  );
  return (
    <>
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          background: "transparent",
          zIndex: "100px",
        }}
        height="92px"
        width="100%"
      >
        <Container maxWidth="lg">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Link href="#">
                <img width="180px" src={logo} alt="logo" />
              </Link>
            </Box>

            {matches ? (
              <Box display={"flex"} justifyContent="flex-end">
                {["left"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      style={{ zIndex: 1 }}
                    >
                      <MenuIcon
                        style={{
                          fontSize: "38px",
                          cursor: "pointer",
                          color: "white",
                        }}
                      ></MenuIcon>
                    </Button>
                    <Paper style={{ background: "#1C0D38" }}>
                      <SwipeableDrawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </Paper>
                  </React.Fragment>
                ))}
              </Box>
            ) : (
              <Box
                display="flex"
                justifyContent={matches1 ? "end" : "space-between"}
                alignItems="center"
              >
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                >
                  <Hidden mdDown>
                    {LinkArray.map(({ title, link }, i) => (
                      <a
                        key={i}
                        href={link}
                        smooth
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <StyledTypography margin={4}>{title}</StyledTypography>
                      </a>
                    ))}

                    {aLinkArray.map(({ title, link }, i) => (
                      <a
                        key={i}
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#fff" }}
                      >
                        <StyledTypography margin={4}>{title}</StyledTypography>
                      </a>
                    ))}

                    {account ? (
                      <CustomButton
                        sx={{ width: "180px" }}
                        onClick={() => disconnect()}
                      >
                        {account.slice(0, 4) + "..." + account.slice(-4)}
                      </CustomButton>
                    ) : (
                      <CustomButton
                        sx={{ width: "180px" }}
                        onClick={() => connect()}
                      >
                        Connect Wallet
                      </CustomButton>
                    )}
                  </Hidden>
                </Box>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </>
  );
}
