import React from "react";
import { Box, Container, Grid, styled, Typography } from "@mui/material";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import TelegramIcon from "@mui/icons-material/Telegram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import RedditIcon from "@mui/icons-material/Reddit";

const bg = "/images/footerBG.png";
const logo = "/images/logo.png";
// import CustomButton from "../Utils/CustomButton";

const Heading = styled(Typography)({
  fontWeight: 700,
  fontSize: "36px",
  textAlign: "center",
  color: "white",
});
const Paragraph = styled(Typography)({
  fontWeight: 400,
  fontSize: "14px",
  color: "#fff",
  textAlign: "center",
  marginTop: "30px",
});

const FooterTEXT = styled(Typography)({
  fontSize: "15px",
});

const FlexBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: 15,
  marginTop: "30px",
});
const IconBox = styled(Box)({
  background: "#403866",
  borderRadius: "7px",
  width: "35px",
  height: "35px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: "#EF4D84",
  },
  transition: "0.4s",
  cursor: "pointer",
});

// const inputStyle = {
//   mt: "30px",
//   width: "100%",
//   background: "rgba(255, 255, 255, 0.05)",
//   border: "1px solid #312F55",
//   borderRadius: "55px",
//   backdropFilter: "blur(8.5px)",
//   fontFamily: "'Open Sans'",
//   fontStyle: "normal",
//   fontWeight: 700,
//   color: "rgba(255, 255, 255, 0.31)",
//   fontSize: "13px",
//   "& label.Mui-focused": {
//     color: "rgba(255, 255, 255, 0.31)",
//   },
//   "& .MuiInput-underline:after": {
//     border: "1px solid #312F55",
//     borderRadius: "55px",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       border: "1px solid #312F55",
//       borderRadius: "55px",
//     },
//     "&:hover fieldset": {
//       border: "1px solid #312F55",
//       borderRadius: "55px",
//     },
//     "&.Mui-focused fieldset": {
//       border: "1px solid #312F55",
//       borderRadius: "55px",
//     },
//   },
//   input: {
//     fontFamily: "'Open Sans'",
//     fontStyle: "normal",
//     color: "rgba(255, 255, 255, 0.31)",
//     fontWeight: 700,
//     fontSize: "13px",
//     border: "none",
//     borderRadius: "55px",
//     "&::placeholder": {
//       textOverflow: "ellipsis !important",
//       color: "white",
//     },
//   },
// };

const icons = [
  {
    icon: <TwitterIcon />,
    link: "https://twitter.com/RenQ_Finance",
  },
  {
    icon: <FacebookIcon />,
    link: "https://www.facebook.com/renqfinance",
  },
  {
    icon: <InstagramIcon />,
    link: "https://www.instagram.com/renqfinance/",
  },
  {
    icon: <TelegramIcon />,
    link: "https://t.me/renqfinance",
  },
  {
    icon: <YouTubeIcon />,
    link: "https://www.youtube.com/@renqfinance",
  },
  {
    icon: <RedditIcon />,
    link: "https://www.reddit.com/r/renq",
  },
  {
    icon: <i className="fa-brands fa-medium"></i>,
    link: "https://medium.com/@renq",
  },
];

const Footer = () => {
  return (
    <Box
      sx={{ background: `url(${bg})`, backgroundSize: "100% 100%", py: "30px" }}
    >
      {/* <Heading sx={{ fontWeight: 700, fontSize: { md: "36px", xs: "25px" } }}>
          Don't Miss Out, Stay Updated
        </Heading>

        <Container maxWidth="md">
          <TextField
            autoComplete="off"
            sx={inputStyle}
            id="standard-name"
            placeholder="Enter your email address"
            InputProps={{
              endAdornment: (
                <CustomButton
                  sx={{
                    fontWeight: 700,
                    fontSize: { md: "20px", xs: "14px" },
                    px: "40px",
                  }}
                >
                  Subscribe
                </CustomButton>
              ),
            }}
          />
        </Container> */}
      <Container>
        <Heading sx={{ fontWeight: 700, fontSize: { md: "36px", xs: "25px" } }}>
          Join Our Community
        </Heading>

        <FlexBox>
          {icons.map(({ link, icon }, i) => {
            return (
              <a
                key={i}
                href={link}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <IconBox>{icon}</IconBox>
              </a>
            );
          })}
        </FlexBox>

        <Box
          p={2}
          mt={10}
          fontSize={{ xs: "13px", md: "15px" }}
          color="#938AC1"
          border="2px solid #fff"
          borderRadius="20px"
        >
          Cryptocurrency may be unregulated in your jurisdiction. The value of
          cryptocurrencies may go down as well as up. Profit may be subject to
          capital gains or other taxes application in your jurisdiction
        </Box>
      </Container>
      <Box
        sx={{
          height: "2px",
          background: "rgba(255, 255, 255, 0.08)",
          my: "50px",
        }}
      ></Box>
      <Container>
        <Grid container spacing={5} mb={8}>
          <Grid item xs={12} sm={6}>
            <img src={logo} alt="" width="200px" />
            <FooterTEXT sx={{ color: "#938AC1" }} mt={1}>
              RENQ LIMITED
              <br></br>
              Registration Number: 236483
            </FooterTEXT>

            <FooterTEXT sx={{ color: "#938AC1" }}>
              Mahe, Seychelles RENQ LTD
            </FooterTEXT>
            <FooterTEXT sx={{ color: "#938AC1" }} mt={2}>
              Email Us:{" "}
              <a
                href="mailto:support@renq.io"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#fff",
                }}
              >
                support@renq.io
              </a>
            </FooterTEXT>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FooterTEXT
              sx={{ color: "#079BAB", fontSize: "18px", fontWeight: "700" }}
            >
              Resources
            </FooterTEXT>
            <a
              href=" https://renq.io/contact/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              <FooterTEXT mt={1.5}>Contact Us</FooterTEXT>
            </a>
            <a
              href="https://renq.io/new-to-crypto"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              <FooterTEXT mt={1}>New To Crypto</FooterTEXT>
            </a>
            <a
              href="https://renq.io/certik-audit/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              <FooterTEXT mt={1}>Audit</FooterTEXT>
            </a>
          </Grid>
          <Grid item xs={12} sm={3}>
            <FooterTEXT
              sx={{ color: "#079BAB", fontSize: "18px", fontWeight: "700" }}
            >
              Agreements
            </FooterTEXT>
            <a
              href="https://renq.io/terms.pdf"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#fff",
              }}
            >
              <FooterTEXT mt={1.5}>Terms & Conditions</FooterTEXT>
            </a>
            <a
              href="https://renq.io/privacy.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <FooterTEXT mt={1}>Privacy</FooterTEXT>
            </a>
            <a
              href="https://renq.io/cookies.pdf"
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <FooterTEXT mt={1}>Cookies</FooterTEXT>
            </a>
          </Grid>
        </Grid>
      </Container>

      <Paragraph sx={{ color: "#938AC1" }}>
        Copyright © 2023 -{" "}
        <a
          href="https://renq.io"
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "none",
            color: "#fff",
          }}
        >
          Renq Finance
        </a>{" "}
        All Rights Reserved.
      </Paragraph>
    </Box>
  );
};

export default Footer;
