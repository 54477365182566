import "./App.css";
import "../src/index.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useRef, useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { ThemeProvider } from "@mui/material";
import theme from "../src/theme";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "../components/Layout";
import { AppContextProvider } from "../src/utils";

export default function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });
  //   const { scrollRef } = useContext(UserContext);

  useEffect(() => {
    window.scrollTo(0, scrollRef.current.scrollPos);
    const handleScrollPos = () => {
      scrollRef.current.scrollPos = window.scrollY;
    };
    window.addEventListener("scroll", handleScrollPos);
    return () => {
      window.removeEventListener("scroll", handleScrollPos);
    };
  });

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />

        <title>Renq Finance Presale - A Unified DeFi Platform</title>
        <meta name="”robots”" content="index, follow" />
        <meta
          name="description"
          content="RenQ Finance multi chain wallet unites decentralized protocols whose synergy enables the most lucrative, fastest and protected operations in the DeFi space."
        />
        <meta
          name="keywords"
          content="RenQ, renq, RenQ Finance, RenQ Finance Presale, Presale, ICO, IEO, IDO, renq finance, renq finance presale, RENQ Token, $RENQ"
        />

        <meta
          property="og:site_name"
          content="Renq Finance Presale - A Unified DeFi Platform"
        />
        <meta property="og:url" content="https://renq.io" />
        <meta
          property="og:title"
          content="Renq Finance Presale - A Unified DeFi Platform"
        />
        <meta property="og:type" content="website" />
        <meta name="author" content="RenQFinance" />
        <meta
          property="og:description"
          content="RenQ Finance multi chain wallet unites decentralized protocols whose synergy enables the most lucrative, fastest and protected operations in the DeFi space."
        />
        <meta property="og:image" content="https://renq.io/images/main.jpg" />
        <meta
          property="og:image:secure_url"
          content="https://renq.io/images/main.jpg"
        />
        <meta name="twitter:site" content="@RenQ_Finance" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Renq Finance Presale - A Unified DeFi Platform"
        />
        <meta name="twitter:image" content="https://renq.io/images/main.jpg" />
        <meta
          name="twitter:description"
          content="RenQ Finance multi chain wallet unites decentralized protocols whose synergy enables the most lucrative, fastest and protected operations in the DeFi space."
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/images/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/images/favicon-16x16.png"
        />
        <link rel="manifest" href="site.webmanifest" />
      </Head>
      <ThemeProvider theme={theme}>
        <AppContextProvider>
          <Layout>
            <CssBaseline />
            <Component {...pageProps} />
          </Layout>
        </AppContextProvider>
      </ThemeProvider>
    </div>
  );
}
